import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { SingleSection } from '../components/sections'
import { Col, Image } from 'react-bootstrap'

import wip from '../images/wip2.png'

const NotFoundPage = () => (

  <Layout>
    <SEO title='404: Not found' />
    <SingleSection id='sci'>
      <Col md={12} className='text-center heading-section'>
        <h3>Pagina non trovata</h3>
        <Image src={wip} fluid />
        {/* <img src={wip} className='img-fluid' style={{ margin: '0 auto' }} alt='' /> */}
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Col>
    </SingleSection>

  </Layout>
)

export default NotFoundPage
